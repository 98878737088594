import { COUNTRY_CODES } from "src/common/constants/countryCodes";
import { I18n } from "@lingui/core";

export enum CountryCodes {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AL = "AL",
  AM = "AM",
  AR = "AR",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BR = "BR",
  BT = "BT",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GI = "GI",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GT = "GT",
  HK = "HK",
  HN = "HN",
  HR = "HR",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KN = "KN",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  ML = "ML",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  NA = "NA",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  SA = "SA",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SI = "SI",
  SK = "SK",
  SM = "SM",
  SO = "SO",
  SV = "SV",
  SY = "SY",
  TC = "TC",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TM = "TM",
  TN = "TN",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  US = "US",
  UZ = "UZ",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VN = "VN",
  VU = "VU",
  WS = "WS",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export const GeosWithAccessToProPackages = [
  CountryCodes.GB,
  CountryCodes.US,
  CountryCodes.DE,
  CountryCodes.FR,
];

export const EurozoneGeosExcludingDEandAT = [
  CountryCodes.BE,
  CountryCodes.CY,
  CountryCodes.EE,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GR,
  CountryCodes.IE,
  CountryCodes.IT,
  CountryCodes.LV,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.PT,
  CountryCodes.SK,
  CountryCodes.SI,
];

export const NonCoreGeos = [
  CountryCodes.BG,
  CountryCodes.HR,
  CountryCodes.CZ,
  CountryCodes.HU,
  CountryCodes.RO,
  CountryCodes.NO,
  CountryCodes.CH,
  CountryCodes.ZA,
];

export const NonCoreEuroGeos = [
  CountryCodes.AX,
  CountryCodes.BE,
  CountryCodes.BL,
  CountryCodes.CY,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GF,
  CountryCodes.GP,
  CountryCodes.GR,
  CountryCodes.IE,
  CountryCodes.IT,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MC,
  CountryCodes.MF,
  CountryCodes.MQ,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.PM,
  CountryCodes.PT,
  CountryCodes.RE,
  CountryCodes.SI,
  CountryCodes.SK,
  CountryCodes.SM,
  CountryCodes.YT,
];

export const countryCodeToName = (i18n: I18n) =>
  COUNTRY_CODES(i18n).reduce(
    (acc, country) => {
      acc[country.code as CountryCodes] = country.name;
      return acc;
    },
    {} as Record<CountryCodes, string>
  );

export type CountryCodeSupported = Extract<
  CountryCodes,
  | CountryCodes.AT
  | CountryCodes.AU
  | CountryCodes.AX
  | CountryCodes.BE
  | CountryCodes.BG
  | CountryCodes.BL
  | CountryCodes.CA
  | CountryCodes.CH
  | CountryCodes.CY
  | CountryCodes.CZ
  | CountryCodes.DE
  | CountryCodes.DK
  | CountryCodes.EE
  | CountryCodes.ES
  | CountryCodes.FI
  | CountryCodes.FR
  | CountryCodes.GB
  | CountryCodes.GF
  | CountryCodes.GP
  | CountryCodes.GR
  | CountryCodes.HR
  | CountryCodes.HU
  | CountryCodes.IE
  | CountryCodes.IT
  | CountryCodes.LT
  | CountryCodes.LU
  | CountryCodes.LV
  | CountryCodes.MC
  | CountryCodes.MF
  | CountryCodes.MQ
  | CountryCodes.MT
  | CountryCodes.NL
  | CountryCodes.NO
  | CountryCodes.NZ
  | CountryCodes.PL
  | CountryCodes.PM
  | CountryCodes.PT
  | CountryCodes.RE
  | CountryCodes.RO
  | CountryCodes.SE
  | CountryCodes.SI
  | CountryCodes.SK
  | CountryCodes.SM
  | CountryCodes.US
  | CountryCodes.YT
  | CountryCodes.ZA
>;

export const CountryCodeSupported: Record<
  CountryCodeSupported,
  CountryCodeSupported
> = {
  [CountryCodes.AT]: CountryCodes.AT,
  [CountryCodes.AU]: CountryCodes.AU,
  [CountryCodes.AX]: CountryCodes.AX,
  [CountryCodes.BE]: CountryCodes.BE,
  [CountryCodes.BG]: CountryCodes.BG,
  [CountryCodes.BL]: CountryCodes.BL,
  [CountryCodes.CA]: CountryCodes.CA,
  [CountryCodes.CH]: CountryCodes.CH,
  [CountryCodes.CY]: CountryCodes.CY,
  [CountryCodes.CZ]: CountryCodes.CZ,
  [CountryCodes.DE]: CountryCodes.DE,
  [CountryCodes.DK]: CountryCodes.DK,
  [CountryCodes.EE]: CountryCodes.EE,
  [CountryCodes.ES]: CountryCodes.ES,
  [CountryCodes.FI]: CountryCodes.FI,
  [CountryCodes.FR]: CountryCodes.FR,
  [CountryCodes.GB]: CountryCodes.GB,
  [CountryCodes.GF]: CountryCodes.GF,
  [CountryCodes.GP]: CountryCodes.GP,
  [CountryCodes.GR]: CountryCodes.GR,
  [CountryCodes.HR]: CountryCodes.HR,
  [CountryCodes.HU]: CountryCodes.HU,
  [CountryCodes.IE]: CountryCodes.IE,
  [CountryCodes.IT]: CountryCodes.IT,
  [CountryCodes.LT]: CountryCodes.LT,
  [CountryCodes.LU]: CountryCodes.LU,
  [CountryCodes.LV]: CountryCodes.LV,
  [CountryCodes.MC]: CountryCodes.MC,
  [CountryCodes.MF]: CountryCodes.MF,
  [CountryCodes.MQ]: CountryCodes.MQ,
  [CountryCodes.MT]: CountryCodes.MT,
  [CountryCodes.NL]: CountryCodes.NL,
  [CountryCodes.NO]: CountryCodes.NO,
  [CountryCodes.NZ]: CountryCodes.NZ,
  [CountryCodes.PL]: CountryCodes.PL,
  [CountryCodes.PM]: CountryCodes.PM,
  [CountryCodes.PT]: CountryCodes.PT,
  [CountryCodes.RE]: CountryCodes.RE,
  [CountryCodes.RO]: CountryCodes.RO,
  [CountryCodes.SE]: CountryCodes.SE,
  [CountryCodes.SI]: CountryCodes.SI,
  [CountryCodes.SK]: CountryCodes.SK,
  [CountryCodes.SM]: CountryCodes.SM,
  [CountryCodes.US]: CountryCodes.US,
  [CountryCodes.YT]: CountryCodes.YT,
  [CountryCodes.ZA]: CountryCodes.ZA,
};

export const CountryCodeNotSupported = [
  CountryCodes.ID,
  CountryCodes.VN,
  CountryCodes.UA,
  CountryCodes.BD,
];

export const CountryCodeSupportedBankAccountData = [
  CountryCodes.AT,
  CountryCodes.BE,
  CountryCodes.BG,
  CountryCodes.CY,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.DK,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GB,
  CountryCodes.GR,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.IE,
  CountryCodes.IS,
  CountryCodes.IT,
  CountryCodes.LI,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.NO,
  CountryCodes.PL,
  CountryCodes.PT,
  CountryCodes.RO,
  CountryCodes.SE,
  CountryCodes.SI,
  CountryCodes.SK,
];
