import { FC } from "react";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import {
  FullStoryAllowedRoutes,
  KccFullStoryAllowedRoutes,
} from "src/common/routing/routes";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";

import { FullStoryRecorder } from "./FullStoryRecorder";

interface FullStoryProps {
  withOptimizely?: boolean;
}

export const FullStory: FC<FullStoryProps> = ({ withOptimizely = false }) => {
  if (withOptimizely) {
    return <FullStoryWithOptimizely />;
  }

  return <FullStoryRecorder routes={FullStoryAllowedRoutes} />;
};

const FullStoryWithOptimizely: FC<FullStoryProps> = () => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY,
  });

  const routes = [...FullStoryAllowedRoutes];

  if (isVariationOn) {
    routes.push(...KccFullStoryAllowedRoutes);
  }

  return <FullStoryRecorder routes={routes} />;
};
