import {
  Currency,
  SchemeScheme as SuccessPlusScheme,
} from "@gocardless/api/dashboard/types";
export { Currency } from "@gocardless/api/dashboard/types";
import { t } from "@lingui/macro";
import { Glyph } from "@gocardless/flux-react";

import { CountryCodes } from "./country";

export const currencyToName = (): Record<Currency, string> => ({
  [Currency.Aud]: t({ id: "Australian Dollar", message: "Australian Dollar" }),
  [Currency.Cad]: t({ id: "Canadian Dollar", message: "Canadian Dollar" }),
  [Currency.Dkk]: t({ id: "Danish Krone", message: "Danish Krone" }),
  [Currency.Eur]: t({ id: "Euro", message: "Euro" }),
  [Currency.Gbp]: t({ id: "British Pound", message: "British Pound" }),
  [Currency.Nzd]: t({
    id: "New Zealand Dollar",
    message: "New Zealand Dollar",
  }),
  [Currency.Sek]: t({ id: "Swedish Krona", message: "Swedish Krona" }),
  [Currency.Usd]: t({ id: "US Dollar", message: "US Dollar" }),
});

export const currencyToNamePluralized = (): Record<Currency, string> => ({
  [Currency.Aud]: t({
    id: "Australian Dollars",
    message: "Australian Dollars",
  }),
  [Currency.Cad]: t({ id: "Canadian Dollars", message: "Canadian Dollars" }),
  [Currency.Dkk]: t({ id: "Danish Kroner", message: "Danish Kroner" }),
  [Currency.Eur]: t({ id: "Euros", message: "Euros" }),
  [Currency.Gbp]: t({ id: "British Pounds", message: "British Pounds" }),
  [Currency.Nzd]: t({
    id: "New Zealand Dollars",
    message: "New Zealand Dollars",
  }),
  [Currency.Sek]: t({ id: "Swedish Kronor", message: "Swedish Kronor" }),
  [Currency.Usd]: t({ id: "US Dollars", message: "US Dollars" }),
});

export const currencyToSuccessPlusScheme: Record<Currency, SuccessPlusScheme> =
  {
    [Currency.Aud]: SuccessPlusScheme.Becs,
    [Currency.Cad]: SuccessPlusScheme.Pad,
    [Currency.Dkk]: SuccessPlusScheme.Betalingsservice,
    [Currency.Eur]: SuccessPlusScheme.SepaCore,
    [Currency.Gbp]: SuccessPlusScheme.Bacs,
    [Currency.Nzd]: SuccessPlusScheme.BecsNz,
    [Currency.Sek]: SuccessPlusScheme.Autogiro,
    [Currency.Usd]: SuccessPlusScheme.Ach,
  };

export const currencyToCountryIconName: Record<Currency, Glyph> = {
  [Currency.Aud]: Glyph.FlagAU,
  [Currency.Cad]: Glyph.FlagCA,
  [Currency.Dkk]: Glyph.FlagDK,
  [Currency.Eur]: Glyph.FlagEU,
  [Currency.Gbp]: Glyph.FlagUK,
  [Currency.Nzd]: Glyph.FlagNZ,
  [Currency.Sek]: Glyph.FlagSE,
  [Currency.Usd]: Glyph.FlagUS,
};

export const currencyToSymbol: Record<Currency, string> = {
  [Currency.Aud]: "$",
  [Currency.Cad]: "$",
  [Currency.Dkk]: "kr.",
  [Currency.Eur]: "€",
  [Currency.Gbp]: "£",
  [Currency.Nzd]: "$",
  [Currency.Sek]: "kr",
  [Currency.Usd]: "$",
};

export const stringToCurrencyType: ReadonlyMap<string, Currency> = new Map([
  ["GBP", Currency.Gbp],
  ["EUR", Currency.Eur],
  ["USD", Currency.Usd],
  ["DKK", Currency.Dkk],
  ["SEK", Currency.Sek],
  ["AUD", Currency.Aud],
  ["NZD", Currency.Nzd],
  ["CAD", Currency.Cad],
]);

export const currencyTypeToString: ReadonlyMap<Currency, string> = new Map(
  Array.from(stringToCurrencyType.keys()).map((currencyString) => [
    stringToCurrencyType.get(currencyString) as Currency,
    currencyString,
  ])
);

// inspired by Country#domestic_currency_for()
// https://github.com/gocardless/payments-service/blob/master/lib/constants/country.rb#L434-L450

export const countryToCurrency = (countryCode: CountryCodes): Currency => {
  const countryToCurrencyMap: Partial<Record<CountryCodes, Currency>> = {
    [CountryCodes.AU]: Currency.Aud,
    [CountryCodes.CA]: Currency.Cad,
    [CountryCodes.DK]: Currency.Dkk,
    [CountryCodes.SE]: Currency.Sek,
    [CountryCodes.NZ]: Currency.Nzd,
    [CountryCodes.GB]: Currency.Gbp,
    [CountryCodes.US]: Currency.Usd,
  };

  return countryToCurrencyMap[countryCode] ?? Currency.Eur;
};

export const currencyToReferenceValidation: Record<Currency, number> = {
  [Currency.Usd]: 10,
  [Currency.Aud]: 30,
  [Currency.Dkk]: 15,
  [Currency.Eur]: 140,
  [Currency.Gbp]: 10,
  [Currency.Nzd]: 12,
  [Currency.Sek]: 11,
  [Currency.Cad]: 12,
};
