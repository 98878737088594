import { useRouter } from "next/router";

interface UseFullStory {
  enableFullStory: boolean;
}

/**
 * for the logic to decide whether we should enable fullstory
 * returns a boolean `enableFullStory`
 */
export const useFullStory = (routes: string[]): UseFullStory => {
  const { route } = useRouter();

  const isAllowedRoute = routes.includes(route);

  return {
    enableFullStory: isAllowedRoute,
  };
};
